.toggle-checkbox:checked {
  right: 0;
  border-color: #3b82f6;
}

.toggle-label {
  transition: background-color 0.2s ease;
}

.toggle-checkbox {
  right: 0;
  transition: all 0.2s ease;
  z-index: 1;
}

.toggle-checkbox:checked+.toggle-label {
  background-color: #3b82f6;
}